angular.module('buybuy', [
  'ngTable'
  ,'angular-cache'
  ,'ngDialog'
  ,'buybuy.controller.edm'
])
.config(function ($httpProvider) {
    delete $httpProvider.defaults.headers.common['X-Requested-With'];
})
.config(function (CacheFactoryProvider) {
    angular.extend(CacheFactoryProvider.defaults, { maxAge: 15 * 60 * 1000, storageMode: 'localStorage' });
})
.config(['ngDialogProvider', function (ngDialogProvider) {
  ngDialogProvider.setDefaults({
      className: 'ngdialog-theme-wb',
      plain: false,
      showClose: false,
      closeByDocument: true,
      closeByEscape: true
  })
}]);
