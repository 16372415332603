'use strict';

angular.module('buybuy.controller.edm', [])
    .controller('EdmController', ['$scope', '$rootScope', '$log', '$http',
        function($scope, $rootScope, $log, $http) {
            $scope.isLoading = false;

            // var target = "#editor-container",
            //     editor = "http://buybuy.app/bbe/index.html?mode="+,
            //     spinner = "https://s3-ap-southeast-2.amazonaws.com/buybuy/public/img/loading.gif",
            //     template = {
            //         identifier: angular.element($('input[name=identifier]')).val(),
            //         id: angular.element($('input[name=emailId]')).val(),
            //         mode: angular.element($('input[name=mode]')).val(),
            //         name: angular.element($('input[name=template]')).val()
            //     },

            //     init = function() {
            //         iframe = document.createElement('iframe'),
            //             iframe.id = "ifrm",
            //             iframe.src = editor,
            //             iframe.setAttribute("style", 'height:100%;width:100%;border:0px;background: #32373A url("' + spinner + '") no-repeat fixed center;');

            //         $(target).append(iframe);

            //         toggleSpinner();
            //     },

                toggleSpinner = function() {
                    $scope.isLoading = !$scope.isLoading;
                },

                update = function() {
                    //Perform a $http call to save the template and get the inserted ID
                    $http({
                        type: "POST",
                        url: "http://buybuy.app/api/json/post/templates/save",
                        data: template,
                        success: function(data) {
                            data = JSON.parse(data);
                            if (data.last_insert_id) {
                                template.id = data.last_insert_id;
                                $('input[name=emailId]').val(data.last_insert_id);
                                vex.dialog.alert('The template [' + template.name + '] has been saved.');
                            } else {
                                console.log('updated');
                                vex.dialog.alert('The template [' + template.name + '] has been updated.');
                            }
                        },
                        error: function(data) {
                            console.log('Error: ' + data);
                        }
                    });
                },

                save = function() {

                    if (template.name === '') {
                        //This is a new template so show the prompt dialog
                        vex.dialog.open({
                            message: 'Enter the template name:',
                            input: "<input name=\"template\" type=\"text\" placeholder=\"Template Name\" required />",
                            buttons: [
                                $.extend({}, vex.dialog.buttons.YES, {
                                    text: 'Save'
                                }), $.extend({}, vex.dialog.buttons.NO, {
                                    text: 'Cancel'
                                })
                            ],
                            callback: function(data) {
                                if (data === false) {
                                    return console.log('Cancelled');
                                }
                                //Add the template name to the DOM
                                $('input[name=template]').val(data.template);
                                template.name = data.template;
                                template.id = '';

                                //Run the update function
                                update();

                            }
                        });


                    } else {
                        //Run the update function
                        update();
                    }
                },

                storeAs = function(filename, content) {
                    saveAs(new Blob([content], {
                        type: 'text/plain;charset=utf-8'
                    }), filename);
                },

                test = function(htmlFile) {
                    var send2Email = document.getElementById('integrator-test-emails').value;

                    if (send2Email === '') {
                        vex.dialog.alert('Error: Please add an email address before sending a test message.');
                        return false;
                    }

                    var message = {
                        "text": "Template Test",
                        "to": send2Email.split(","),
                        "subject": "Buybuy Email Template Test",
                        "data": htmlFile
                    };

                    $http({
                        type: "POST",
                        url: "http://buybuy.app/api/json/post/templates/sendtest",
                        data: message,
                        success: function(data) {
                            console.log(data);
                        },
                        error: function(data) {
                            console.log('Error: ' + data);
                        }
                    });
                },

                getTemplate = function() {

                },

                loadTemplate = function() {

                };


        }
    ]);